import React, { useEffect } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import LeftCardProfile from "../../../components/layout/LeftCardProfile"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Form from "react-bootstrap/Form"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"

const Settings = () => {
	const [currentPass, setCurrentPass] = React.useState("")
	const [confirmPass, setConfirmPass] = React.useState("")
	const [newPass, setNewPass] = React.useState("")
	const [userToken, setUserToken] = React.useState("")
	const [userId, setUserId] = React.useState("")
	const [userData, setUserData] = React.useState({})

	useEffect(() => {
		setUserToken(localStorage.getItem("token"))
		setUserId(localStorage.getItem("userId"))
		let userDataStore = localStorage.getItem("userData")
		setUserData(JSON.parse(userDataStore))
	}, [])
	const updatePass = () => {
		const update = {
			confirmPassword: confirmPass,
			currentPassword: currentPass,
			id: userId,
			newPassword: newPass,
		}

		axios
			.put("https://api.galeahealth.com/app/user/update-password", update, {
				headers: {
					Authorization: userToken,
					// contentType: 'multipart/form-data',
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data)
					toast.success("Password Updated Successfully")
				}
			})
			.catch((err) => {
				toast.error("Error Updating Password")
				console.log(err)
			})
	}

	return (
		<div className={styles.providerProfileMain}>
			<div>
				<Container className="">
					<div className="row pt-5">
						<Col md={4} className="pb-3">
							<LeftCardProfile img={userData.image_url} name={userData.name} />
						</Col>
						<Col md={8}>
							<div className={styles.rightCard1}>
								<div className={styles.account}>
									<h4 className="text-center">Settings</h4>
									<p className="text-center">Reset your password and configure your notifications</p>
								</div>
							</div>
							<div className={`${styles.add} ${styles.contactInfo}`}>
								<h3 className="mt-5">Change Password</h3>
							</div>
							<Row>
								<Col md={5}>
									<div className={styles.rightCard1}>
										<div className={styles.account}>
											<FloatingLabel controlId="floatingPassword" label="Current Password">
												<Form.Control
													type="password"
													value={currentPass}
													onChange={(e) => setCurrentPass(e.target.value)}
													placeholder="Current Password*"
												/>
											</FloatingLabel>
											<FloatingLabel controlId="floatingPassword" label="New Password*">
												<Form.Control
													type="password"
													value={newPass}
													onChange={(e) => setNewPass(e.target.value)}
													placeholder="New Password"
												/>
											</FloatingLabel>
											<FloatingLabel controlId="floatingPassword" label="Confirm Password">
												<Form.Control
													type="password"
													value={confirmPass}
													onChange={(e) => setConfirmPass(e.target.value)}
													placeholder="Confirm Password"
												/>
											</FloatingLabel>
											<Button onClick={updatePass} className="mt-4 ps-5 pe-5">
												Submit
											</Button>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
					</div>
				</Container>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				{/* Same as */}
				<ToastContainer />
			</div>
		</div>
	)
}

export default Settings
