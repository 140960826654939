import React from "react"
import { Main } from "../components/layout"
import Settings from "../components/pages/settings"

const SettingsPage = () => {
	return (
		<Main>
			<Settings />
		</Main>
	)
}

export default SettingsPage
